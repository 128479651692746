import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const NonSurgicalHairReplacementforWomen = () => {

  const seoData = {
    title: 'Non Surgical Hair Replacement for Women in Delhi - Radiance',
    description: 'Non Surgical Hair Replacement for women in Delhi, give you the appearance you need. Radiance Hair Studio offers the best in class services throughout Delhi.',
    keywords: ['non surgical hair replacement for women'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "mainEntityOfPage": "Service Page",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Non-surgical-Hair-Replacement-for-Women's.jpg",
      "https://www.radiancehairstudio.com/non-surgical-hair-replacement1.png",
      "https://www.radiancehairstudio.com/non-surgical-hair-replacement2.png"
    ],
    "additionalType": "https://www.radiancehairstudio.com/non-surgical-hair-replacement.php",
    "category": "Hair Wigs",
    "description": "Non Surgical Hair Replacement for women in Delhi, give you the appearance you need. Radiance Hair Studio offers the best in class services throughout Delhi.",
    "disambiguatingDescription": "Non-surgical hair replacement for women",
    "serviceType": "Wig for Women"
  };
  


  const sectionS1 = [
    {
      title: "Customized Wigs for Women",
      img: "/custom-wigs.png",
    },
    {
      title: "Wig for Women’s",
      img: "/womens-wig.png",
    },
    {
      title: "Hair Replacement",
      img: "/hair-replacement.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/non.png",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Non-surgical-Hair-Replacement-for-Women's.jpg",
      content: (
        <div>
          <h2>Non-surgical Hair Replacement for Women's</h2>
          <p>
          Nowadays, hair loss is a common issue for women in urban areas. There are oral medications on the market that claim to help you grow hair naturally.But because you tested them all, you should already know how successful they are. When you're in such a dire situation, non-surgical hair replacement with wig support can be the ideal answer.
          </p>
          <p>
          This specific hair restoration technique requires the involvement of specialised specialists. Radiance Hair Studio is here to support you and guarantee the greatest outcomes. To offer you the most natural look possible, our professionals are skilled in the technique and knowledgeable about the glues and tapes. Get in touch with us to observe the transformation of your appearance.
          </p>
          <h2>Non-Surgical Hair Replacement – A Premium Solution for Women</h2>
          <p>
          In contrast to surgical hair replacement, <a href="/non-surgical-hair-replacement-for-men.php" style={{ color: "#373737" }}>non-surgical hair restoration</a> uses straightforward techniques to give you the desired appearance. Therefore, the travel is easy and painless for everyone. You just need the right kind of tape, glue, or clip.
          </p>
          <p>
          With the highest quality glue, immaculate tapes or clips, and human hair—all at prices well within your means—Radiance Hair Studio once again stands flawlessly for you. 
          </p>
        </div>
      )
    }
  ];

  const sectionS3 = [
    {
      smallImg: "/non-surgical-hair-replacement1.png",
      largeImg: "/non-surgical-hair-replacement2.png",
      content: (
        <div>
          <h2>Advantages of Choosing Our Non-Surgical Hair Replacement</h2>
          <p>Our non surgical hair replacement in delhi is an affordable solution, making it a popular choice among many individuals looking for a natural transformation.</p>
          <ul >
            <li>
              <strong>Safe and Side-Effect Free</strong>
              Since the hair system is applied externally, there are no risks, discomfort, or side effects involved. It blends seamlessly without putting any strain on your scalp.
            </li>
            <li>
              <strong>Versatile Styling Options</strong>
             One of the best aspects of this procedure is the freedom to experiment with different <a href="/services.php" style={{ color: "#373737" }}>extension</a> hairstyles. Whether you prefer a sleek bob, a stylish neck-length cut, or long flowing hair, you can choose the perfect look that suits you.
            </li>
            <li>
              <strong>Long-Lasting and Hassle-Free</strong>
              Designed for durability, our hair replacement solution allows you to enjoy your new appearance confidently, without constant upkeep or worries.
            </li>
            <li>
            Experience a worry-free, natural-looking transformation with our expert non-surgical hair replacement services!
            </li>
          </ul>
          <p></p>
        </div>
      )
    }
  ];

  const sectionS4 = [
    {
      title: "Radiance’s answer to your FAQ",
      description: "You may have some questions in light of the aforementioned statements. Answers to some of your frequently asked questions are provided here.",
      description2: "",
      faq: [
        {
          title: "Is the treatment permanent?",
          description: "Hair loss cannot be permanently resolved with non-surgical hair restoration. There will be times when you will need to contact Radiance. Our experts will take the least amount of effort to restore your improved appearance in the new style."
        },
        {
          title: "Can I withstand strong winds or swim while wearing a wig?",
          description: "Absolutely! You can face strong winds or even get your wig wet without any issues. We use high-quality hair and premium adhesives to ensure durability, giving you confidence in all situations."
        },
        {
          title: "Will the treatment cause any discomfort?",
          description: "Not at all! The entire procedure is non-invasive and completely safe, with no side effects. Our skilled professionals ensure a smooth and hassle-free experience, making your transformation effortless."
        },
        {
          title: "What should I know before buying a wig?",
          description: "Everything you need to know about choosing the right wig has been covered above. At RADIANCE, we offer a free consultation to help you find the perfect wig that suits your style and needs."
        },
        {
          title: "Are there any drawbacks to wearing a wig?",
          description: "Drawbacks? When battling hair loss? That’s not the right question! High-quality human hair wigs are a game-changer—completely natural, long-lasting, and free from side effects. What more could you ask for?"
        },
        {
          title: "Can I style my wig differently?",
          description: "Yes, you can experiment with different hairstyles! However, for the best results, visit us at RADIANCE, where our experts will help you achieve your desired look."
        }
      ],
    }
  ];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Wig for Women's" />
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Non-surgical Hair Replacement for Women's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default NonSurgicalHairReplacementforWomen;
